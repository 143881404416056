.page-content {
  margin-left: 250px;
  padding: 39px 0px;
}

.roaster-body {
  padding: 1px 20px;
  margin-top: 75px;
}

.roaster-modal {

  .modal-content {
    border: none;
    border-radius: 11px;
    width: 100%;

    .modal-header {

      @extend .bg-dark-blue;

      @extend .color-white;
      border-top-left-radius: 11px;
      border-top-right-radius: 11px;
      padding: 12px 16px;
    }
  }
}

.list-table {

  table {
    box-shadow: 7px 1px 3px 0px rgba(0, 0, 0, 0.15);

    .thead-light {

      th {

        @extend .bg-light-blues;

        @extend .color-black;
        border: none;
        font-weight: 500;
        white-space: nowrap;
        padding: 18px;

        .font-icon {
          position: relative;

          @extend .color-black;
          opacity: 0.5;

          .up-arrow {
            height: 5px;
            cursor: pointer;
          }

          .down-arrow {
            height: 5px;

            @extend .rot-180;
            position: absolute;
            top: 12px;
            left: 0;
            cursor: pointer;
          }
        }
      }
    }

    tbody {

      tr {

        td {
          color: $dark-grey;
          border-bottom: 1px solid $grey-j;
          border-top: 0;
          padding: 18px;
        }
      }
    }

    td,
    th {
      white-space: nowrap;
    }
  }
}

.image-upload-wrap {
  border: 2px dashed rgba(28, 78, 217, 0.35);
  position: relative;

  .file-upload-input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }

  .drag-text {

    h3 {
      padding: 60px 10px;

      .drag-or {
        margin: 0 20px;
      }
    }
  }
}

.w-80 {
  width: 80%;
}

.roaster-bg-group {

  .list-group-item {
    padding: 0;
    border: 0;

    @extend .mr-10;

    .custom-radio {

      .custom-control-input {

        &:checked ~ .custom-control-label {

          &::after {
            background-image: url(assets/images/check-icon.svg);
          }
        }
      }

      .custom-control-label {

        &::before {
          border: none;
          width: 37px;
          height: 37px;
          border-radius: 5px;
        }

        &::after {
          border: none;
          width: 37px;
          height: 37px;
        }

        &.roaster-color1 {

          &::before {
            background-color: $red-g;
          }
        }

        &.roaster-color2 {

          &::before {
            background-color: $yellow-e;
          }
        }

        &.roaster-color3 {

          &::before {
            background-color: $yellow-f;
          }
        }

        &.roaster-color4 {

          &::before {
            background-color: $grey-k;
          }
        }

        &.roaster-color5 {

          &::before {
            background-color: $pink-a;
          }
        }

        &.roaster-color6 {

          &::before {
            background-color: $blue-h;
          }
        }

        &.roaster-color7 {

          &::before {
            background-color: $green-e;
          }
        }

        &.roaster-color8 {

          &::before {
            background-color: $green-f;
          }
        }
      }
    }
  }
}

.sidebar-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  transition: 0.5s;
  bottom: 0;

  .sidebar-container {
    width: 50%;
    float: right;
    background: $white;
    color: $black;
    height: 100%;
  }
}

.roaster-breakcrumb {

  @extend .bg-white;
  border-radius: 0;
  padding: 15px 20px;
  position: fixed;
  width: 100%;
  top: 60px;
  border-top: 1px solid $grey-l;
  z-index: 1000;

  .breadcrumb-item {

    @extend .color-light-black;

    + .breadcrumb-item {

      &::before {
        content: '|';
      }
    }
  }
}

.roaster-tab {

  .nav-pills {

    .nav-link {
      border-radius: 4px;
      border: 1px solid $dark-blue;
      padding: 5px 20px;

      @extend .color-dark-blue;

      @extend .fs14;
      margin-right: -4px;

      &.active {

        @extend .bg-dark-blue;

        @extend .color-white;
      }
    }
  }

  .tab-content {

    @extend .mt-20;

    .media {

      img {

        @extend .roaster-profile-img;
      }
    }

    .available-data {

      &::before {
        content: url(./assets/images/available.png);

        @extend .availble-icon;
      }
    }

    .not-available-data {

      &::before {
        content: url(./assets/images/not-available.png);

        @extend .availble-icon;
      }
    }
  }
}

.availble-icon {
  position: absolute;
  right: 27px;
  top: 0;
}

//About Staff

.btn-more {
  background: $dark-blue;
  border-radius: 4px;
  padding: 4px 12px;
  font-size: 15px;
  font-weight: normal;
}
//icon colors

.bg-icon-mob,
.bg-icon-envelope,
.bg-icon-lock,
.bg-icon-camera,
.bg-icon-call,
.bg-user,
.bg-icon-file,
.bg-icon-alert,
.bg-icon-equipment,
.bg-icon-clock,
.bg-icon-calendar,
.bg-icon-cogs,
.bg-medal {

  @extend .about-icon-content;
}

.bg-icon-clock:before {
  content: '\f017';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.bg-icon-calendar:before {
  content: '\f133';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.bg-icon-cogs:before {
  content: '\f013';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.bg-user:before {
  content: '\f007';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.bg-icon-call:before {
  content: '\f095';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.bg-medal:before {
  content: '\f5a2';

  @extend .about-icons;

  @extend .bg-dark-blue-icon;
}

.bg-icon-mob:before {
  content: '\f10b';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.bg-icon-envelope:before {
  content: '\f0e0';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.bg-icon-lock:before {
  content: '\f023';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.bg-icon-camera:before {
  content: '\f030';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.bg-icon-file:before {
  content: '\f15b';

  @extend .about-icons;

  @extend .bg-yellow-icon;
}

.bg-icon-alert:before {
  content: '\f06a';

  @extend .about-icons;

  @extend .bg-red-icon;
}

.bg-icon-equipment:before {
  content: '\f109';

  @extend .about-icons;

  @extend .bg-blue-icon;
}

.about-icons {
  position: absolute;
  font-family: 'Font Awesome\ 5 Free', sans-serif;
  top: 0;
  left: 10px;
  font-weight: 900;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
}

.bg-blue-icon {
  color: $dark-blue;
  background: $grey-i;
}

.bg-dark-blue-icon {
  color: $yellow-b;
  background: $dark-blue;
}

.bg-yellow-icon {
  color: $yellow-g;
  background: rgba(248, 215, 0, 0.13);
}

.bg-red-icon {
  color: $dark-red;
  background: rgba(239, 83, 104, 0.13);
}

.about-icon-content {
  padding-left: 60px;
  position: relative;
}

.roaster-profile-img {
  width: 104px;
  height: 104px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0px 3px 6px #00000029;
}

.about-more {

  .dropdown-menu {
    left: auto !important;
    right: 0 !important;
    box-shadow: 0px 3px 6px #00000012;
    border: 1px solid $grey-m;
    margin-top: 6px;

    li {
      cursor: pointer;

      .dropdown-item {

        em {

          @extend .color-dark-blue;

          @extend .fs17;
        }

        @extend .fs13;

        @extend .color-light-black;
        text-transform: uppercase;
        padding: 14px 18px;

        &:active {

          @extend .color-white;
          background-color: $light-sky-blue;

          em {

            @extend .color-white;
          }
        }
      }
    }
  }

  &.number-flag {

    &.show > .btn-primary.dropdown-toggle {
      box-shadow: none;
      color: $white !important;
      background-color: $dark-blue !important;
    }

    .dropdown-menu {
      left: 0 !important;
      max-height: 250px;
      overflow-y: auto;

      li {
        padding: 2px 15px;
      }
    }
  }
}

.roaster-pagination {

  .ngx-pagination {

    li {
      background-color: $grey-n;
      margin-right: 0;
      width: 36px;
      height: 36px;
      /* display: block; */
      line-height: 31px;
      text-align: center;

      a:hover {
        background-color: transparent;
        text-decoration: none;

        @extend .color-dark-blue;
      }

      &.current {
        padding: 0;

        span {

          + span {

            @extend .bg-dark-blue;
            border-radius: 50%;

            @extend .pagination-icon;
            display: block;
            line-height: 36px;
          }
        }
      }

      &:nth-last-child(2) {
        border-top-right-radius: 21px;
        border-bottom-right-radius: 21px;
      }

      &:nth-child(3) {
        border-top-left-radius: 21px;
        border-bottom-left-radius: 21px;
      }

      &.pagination-previous {

        @extend .pagination-icon;
        line-height: 31px;
        border-radius: 50%;
        margin-right: 10px;

        a::before,
        &.disabled::before {
          margin-right: 0;
        }
      }

      &.pagination-next {

        @extend .pagination-icon;
        line-height: 31px;
        border-radius: 50%;
        margin-left: 10px;

        a::after,
        &.disabled::after {
          margin-left: 0;
        }
      }
    }
  }
}

.pagination-icon {
  width: 36px;
  height: 36px;
  text-align: center;
}

.incident-event-img {
  width: 83px;
  height: 83px;
  border-radius: 5px;
  object-fit: scale-down;
}

.file-upload {
  background-color: $white;
  width: 200px;
}

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: $white;
  background: $dark-blue;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 2px solid $dark-blue;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: $dark-blue;
  color: $white;
  transition: all 0.2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-inpu {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload {
  margin-top: 20px;
  border: 2px dashed $dark-blue;
  position: relative;
  height: 136px;
  width: 136px;
  opacity: 0.32;
}

.drag-tex {
  opacity: 0.25;
  margin-top: 45px;
  text-align: center;
}

.transform {
  transform: rotate(180deg);
}

.float-right .opacity-13 {
  background: rgba(28, 18, 218, 0.13);
  color: $dark-blue;
}

.updated-image {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 10px;
  left: 60px;
}

.no-resizer {
  resize: none;
}

.radiocontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */

.radiocontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */

.customradiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $grey-o;
  border-radius: 50%;
  border: 2px solid $dark-blue;
}

/* On mouse-over, add a grey background color */

.radiocontainer:hover input ~ .customradiobtn {
  background-color: $grey-p;
}

/* When the radio button is checked, add a blue background */

.radiocontainer input:checked ~ .customradiobtn {
  background-color: $white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.customradiobtn:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.radiocontainer {

  input:checked ~ .customradiobtn {

    &:after {
      display: block;
    }
  }

  .customradiobtn {

    &:after {
      top: 5px;
      left: 5px;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      background: $dark-blue;
    }
  }
}

.custom-switch {

  .custom-control-input:checked ~ .custom-control-label {

    &::after {
      background-color: $white;
      transform: translateX(0.75rem);
      width: 17px;
      height: 17px;
      left: -32px;
      top: 2px;
      box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0, 0, 0, 0.4);
    }

    &::before {
      color: $white;
      border-color: $light-green;
      background-color: $light-green;
    }
  }

  .custom-control-input:checked ~ .custom-control-label {

    &::before {
      left: -2.35rem;
      width: 1.55rem;
      height: 0.85rem;
      pointer-events: all;
      border-radius: 0.5rem;
    }
  }

  .custom-control-label {

    &::after {
      left: -39px;
      width: 17px;
      height: 17px;
      background-color: $white;
      top: 2px;
      box-shadow: 0 0.0625rem 0.1875rem 0.0625rem rgba(0, 0, 0, 0.4);
    }

    &::before {
      color: $white;
      border-color: $grey-q;
      background-color: $grey-q;
      left: -2.15rem;
      width: 2.05rem;
      height: 0.85rem;
      pointer-events: all;
      border-radius: 0.5rem;
    }
  }
}

.delete-all-shifts {

  ul {
    padding-left: 0px !important;
  }
}

.accordion-table {

  table {
    box-shadow: 7px 1px 3px 0px rgba(0, 0, 0, 0.15);
    border: 1px solid $grey-j;

    .dropdown-toggle {
      border-bottom: none;
      font-weight: 500;
    }

    .dropdown-item {
      padding: 0px;

      &:hover {
        background: transparent;
      }
    }

    .dropdown-menu {
      min-width: 200px;
      padding: 10px;
    }

    button:focus {
      outline: none;
      box-shadow: none;
    }

    .thead-light {

      th {

        @extend .bg-light-blues;

        @extend .color-black;
        border: none;
        font-weight: 500;
        white-space: nowrap;
        text-align: center;

        .font-icon {
          position: relative;

          @extend .color-black;
          opacity: 0.5;

          .up-arrow {
            height: 5px;
            cursor: pointer;
          }

          .down-arrow {
            height: 5px;

            @extend .rot-180;
            position: absolute;

            left: 0;
            cursor: pointer;
          }
        }
      }
    }

    tbody {

      tr {

        td {
          color: $light-black;
          border: 1px solid $grey-j;
          opacity: 0.8;
          font-size: 12px;

          padding: 0px;

          .customClass {
            //border:1px solid $grey-j;
            border: none;
            border-radius: 0%;
            //background-color: transparent;
            margin: 0px;

            .panel-heading {

              @extend .bg-white;
            }

            .card-body {
              padding: 0px !important;
            }
          }
        }
      }
    }

    td,
    th {
      white-space: nowrap;
    }
  }
}

.report-table {

  .incident {

    tbody {

      tr {

        td {
          border: none;
          border-bottom: 1px solid $grey-j;
          text-align: left;
        }
      }
    }
  }

  table {
    box-shadow: 7px 1px 3px 0px rgba(0, 0, 0, 0.15);
    font-size: 12px;
    border: 1px solid $grey-j;

    .border {
      border-right: 1px solid $grey-j;
    }

    .thead-light {

      th {

        @extend .bg-light-blues;

        @extend .color-black;
        border: none;
        font-weight: 500;
        white-space: nowrap;
        padding: 12px;

        .font-icon {
          position: relative;

          @extend .color-black;
          opacity: 0.5;

          .up-arrow {
            height: 5px;
            cursor: pointer;
          }

          .down-arrow {
            height: 5px;

            @extend .rot-180;
            position: absolute;
            top: 12px;
            left: 0;
            cursor: pointer;
          }
        }
      }
    }

    tbody {

      tr {

        td {
          color: $light-black;
          border: 1px solid $grey-j;
          border-top: 0;
          padding: 12px;
          text-align: center;
        }
      }
    }

    td,
    th {
      white-space: nowrap;
    }
  }
}
