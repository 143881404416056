.modal-form {

  .form-group {
    margin-bottom: 23px;

    label {
      font-weight: 500;

      @extend .color-black;
      margin-bottom: 5px;
    }

    .form-control {
      border-color: rgba(112, 112, 112, 0.27);

      @extend .fs14;
      box-shadow: none;
    }
  }

  .input-group {
    margin-bottom: 23px;

    .input-group-prepend {

      .custom-select {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 14px;
        height: 38px;
      }
    }
  }

  .custom-checkbox {

    .custom-control-label {
      padding-left: 5px;
      padding-top: 3px;

      &::before {
        border: $dark-blue solid 2px;
        width: 20px;
        height: 20px;
      }

      &::after {
        border: $dark-blue solid 2px;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.search-box {

  .input-group {

    .input-group-text {
      border-color: $grey-r;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background: $white;
      border-right: 0;
    }

    .form-control {
      border-color: $grey-r;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-left: 0;

      &::placeholder {

        @extend .color-light-grey;
      }
    }
  }
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: $red-i !important;
}

input {

  &.customercreateform {
    height: 50px;
    border: 1px solid $grey-h;
    border-radius: 10px;

    &::placeholder {
      color: $grey-h;
    }
  }
}

input.custominputgroup {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.custombtninputgrp {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.search-box-square {

  .input-group {

    .input-group-text {
      border-color: $grey-r;
      
      background: $white;
      border-right: 0;
    }

    .form-control {
      border-color: $grey-r;
     
      border-left: 0;

      &::placeholder {

        @extend .color-light-grey;
      }
    }
  }

  input:focus {
    outline: none;
    box-shadow: none;
}
}

.report-container {

  .report-container-item {
    min-width: 195px;

    label {
        color:$grey-s;
        font-size: 12px;
        font-weight: 500;
        font-family: 'Roboto', sans-serif;
        margin-bottom: 0px;
        
      }

    .form-group {
      margin-top: 0px;
      margin-bottom: 0px;
      
      button {
        padding: 0px 0px 0px 5px;
       
      }
     
      .input-group {
        margin-top: -11px;

        input {
          background: transparent;
        border:none;
          font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
        }
//        input[type="checkbox"]:checked:after {
//    content: '';
//    position: absolute;
//    width: 1.2ex;
//    height: 0.4ex;
//    background: rgba(42, 218, 19, 0);
//    top: 0.9ex;
//    left: 0.4ex;
//    border: 3px solid blue;
//    border-top: none;
//    border-right: none;
//    -webkit-transform: rotate(-45deg);
//    -moz-transform: rotate(-45deg);
//    -o-transform: rotate(-45deg);
//    -ms-transform: rotate(-45deg);
//    transform: rotate(-45deg);
// }


// input[type="checkbox"]:before {
//    content: "";
//    display: inline-block;
//    vertical-align: -25%;
//    height: 2ex;
//    width: 2ex;
//    background-color: white;
//    border: 1px solid rgb(166, 166, 166);
//    border-radius: 4px;
//    box-shadow: inset 0 2px 5px rgba(0,0,0,0.25);
//    margin-right: 0.5em;
// }
      }
     
    }
    
  }

  input:focus {
    outline: none;
    box-shadow: none;
}

}