//bg colors

.bg-lights-green {
  background-color: $light-green;
}

.bg-light-red {
  background-color: $light-red;
}

.bg-red {
  background-color: $red;
}

.bg-dark-blue {
  background-color: $dark-blue;
}

.bg-grey {
  background-color: $grey;
}

.bg-green-light {
  background-color: $green-light;
}

.bg-light-blues {
  background-color: $light-blues;
}

.bg-white {
  background-color: $white;
}

.bg-light-green {
  background-color: $green;
}

.bg-1 {
  background-color: #e74c3c;
}

.bg-2 {
  background-color: #e67e22;
}

.bg-3 {
  background-color: #f39c12;
}

.bg-4 {
  background-color: #34495e;
}

.bg-5 {
  background-color: #8e44ad;
}

.bg-6 {
  background-color: #3498db;
}

.bg-7 {
  background-color: #27ae60;
}

.bg-8 {
  background-color: #16a085;
}

.bg-gray {
  background-color: #f7f8fa;
}

.bg-lightgreen2 {
  background-color: $light-green;
}

.bg-red2 {
  background-color: $red2;
}

.bg-yellow {
  background-color: $yellow2;
}

.bg-grey {
  background: rgb(245, 245, 245);
}

.bg-violet {
  background: rgba(19, 49, 200, 0.1);
}
//Colors

.color-green {
  color: $dark-green;
}

.color-light-green2 {
  color: $light-green;
}

.color-red {
  color: $dark-red;
}

.color-red2 {
  color: $red2;
}

.color-blue {
  color: $blue;
}

.color-blue2 {
  color: $blue-b;
}

.color-white {
  color: $white;
}

.color-yellow {
  color: $yellow;
}

.color-black {
  color: $black;
}

.color-dark-blue {
  color: $dark-blue;
}

.color-dark-grey {
  color: $dark-grey;
}

.color-grey-text {
  color: $grey-text;
}

.color-light-grey {
  color: $light-grey;
}

.color-light-black {
  color: $light-black;
}

.color-light-green {
  color: $green;
}

.color-dark-cred {
  color: $dark-cred;
}

.text-lightgey {
  color: $grey-g;
}
//border

.border-blue {
  border-color: $dark-blue;
}

.b-color {
  border: 1px solid $blue-f;
}

.b-green {
  border: 2px solid #43e0aa;
}

.b-violet {
  border: 2px solid #935ccb;
}

.b-blue {
  border: 2px solid #83bedf;
}

.b-sky-blue {
  border: 2px solid #24aefc;
}
