/********* font size **********/
$spaceamounts: (8, 10, 12, 13, 14, 15, 16, 17, 18, 20, 22, 23, 24, 30, 35, 40);
$sides: (size); // Leave this variable alone

@each $size in $spaceamounts {

  @each $side in $sides {
    .f#{str-slice($side, 0, 1)}#{$size} {
      font-#{$side}: #{$size}px;
    }
  }
}

/********* font size **********/

.ff-opensans {
  font-family: 'Open Sans', sans-serif;
}

.ff-roboto {
  font-family: 'Roboto', sans-serif;
}

.fw-500 {
  font-weight: 500;
}

.fs-50 {
  font-size: 50px;
}
