.button-light-green {

  @extend .bg-lights-green;

  @extend .color-green;
}

.btn-danger {

  @extend .bg-light-red;

  @extend .color-red;
}

.btn-outline-blue {

  @extend .color-dark-blue;
  border: 1.5px solid $dark-blue;
}

.btn-cancel {

  @extend .bg-grey;

  @extend .color-dark-grey;
}

.btn-create {

  @extend .bg-dark-blue;

  @extend .color-white;
}

.btn-logout {

  @extend .color-dark-blue;

  @extend .bg-white;
  border: 1.5px solid $blue;
}

.btn-logout:hover {

  @extend .color-dark-blue;

  @extend .bg-white;
  border: 1.5px solid $blue;
}

.btn-logout:hover {
  color: #1c50da !important;
  background-color: white !important;
  border: 1.5px solid $blue !important;
}

.button-danger {
  background-color: red;
  color: white;
  font-size: 18px;
  margin-bottom: 1px;
  border-radius: 8px;
}

.button-value {
  background-color: blue;
  color: white;
  font-size: 18px;
  margin-bottom: 1px;
  border-radius: 8px;
}

.createcustomerbtn {
  background-color: #1c50da;
  border-radius: 10px;
  opacity: 1;
}

.btn.selectedfilterbtn:hover {
  color: white !important;
}

.btn-more {
  background: #1c50da;
  border-radius: 4px;
  opacity: 1;
  padding: 3px 5px;
  font-size: 13px;
  font-weight: 600;
}

.btn-default {
  background-color: $dark-blue;
  color: $light-white;
  text-align: center;
  padding: 8px 6px !important;
}

.btn-default:hover {
  background-color: $dark-blue !important;
  color: $light-white !important;
}

.report-container {

  button:focus {
    outline: none;
    box-shadow: none;
  }
}
