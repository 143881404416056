@media (min-width: 320px) and (max-width: 480px) {

  .add-shift {
    flex-direction: column;

    .search-box {
      width: 100%;
    }
  }

  .report-container {
    margin-top:0px;
    
    .report-container-item {
      margin:auto;
     
      
      min-width: 200px;

      .form-group {

        label {
          font-size:14px;
        }

        button {
          font-size:16px;
        }
      }

       .search-box-square {
         min-width: 250px;
       }

       .btn {
         min-width: 250px;
       }

       .input-group {
          min-width: 250px;
       }
    }
  }
}

@media (max-width: 767px) {
  // .report-container{
  //   margin-top:0px;
  //   .report-container-item{
  //     margin:auto;
  //     min-width: 400px;
  //      .search-box-square{
  //        min-width: 292px;
  //      }
  //      button{
  //        min-width: 292px;
  //      }
  //      .input-group{
  //         min-width: 292px;
  //      }
  //   }
  // }
}

@media (max-width: 767px) {
  

  .btn-week {

    button {
      width: 100%;
    }
  }

  .image-upload-wrap {

    .drag-text {

      .drag-or {
        display: block;
        opacity: 0.25;
      }
    }
  }

  .schedule-layout {

    .roaster-date {
      position: relative;
      display: block;
      width: 100%;
      padding: 25px 0px;
      top: 0;
    }

    .custom-icon {
      position: absolute;
      top: -33px;
      margin-bottom: 20px;

      &:first-child {
        right: 40px;
      }

      &:last-child {
        right: 0;
      }
    }

    .scheduler-table {
      display: block;

      .schedule-table {
        table-layout: auto;

        thead {
          position: relative;
          top: auto;
          width: 100%;
          table-layout: initial;
          display: block;

          td {

            &:first-child {
              width: 400px;
            }
          }
        }

        tbody {
          margin-top: 0;
        }

        tr td {
          width: 100%;
          white-space: nowrap;
        }
      }
    }
  }

  .header {

    .navbar {
      background-color: $dark-blue !important;

      .navbar-brand {
        width: 150px;

        .logo {
          margin-left: 20px;
          max-width: 50px;
        }
      }

      .toggle-btn {
        position: absolute;
        top: 5px;
        left: 10px;
      }

      .profile-name {
        color: $white;
        max-width: 70px;
      }
    }
  }

  .widm-140 {
    max-width: 112px;
  }

  .roaster-sidebar {
    z-index: 999999;
  }

  .page-content {
    margin-left: 0px;
    margin-top: 40px;
  }

  .own-sidemenu-main {

    &.sidemenuopend {

      .roaster-sidebar {
        display: none;
        transition: all 0.5s;
      }
    }

    &.sidemenuclosed {

      .roaster-sidebar {
        transition: all 0.5s;
        width: 250px;
      }
    }
  }

  .page-content.show_pagecontent {
    transition: all 0.5s;
    margin-left: 0px;
  }

  .fa-bars {
    margin-top: 10px;
    color: $white;
  }

  .custommargin {
    margin-top: 75px !important;
  }

  .selectedfilterbtn {
    margin-left: 15px;
  }

  .list-table {
    margin-top: 15px;
  }
}

@media (max-width: 768px) {

  .roaster-modal {
    max-width: 99%;
  }
}

@media (min-width: 768px) {

  .filterdropdown.dropdown-menu {
    margin: -0.5rem 0 0 !important;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  /*Schedule Layout*/

  .user-profile {

    p {
      max-width: 55px;
    }
  }

  .schedule-layout {

    .roaster-date {
      position: relative;
      display: block;
      width: 100%;
      padding: 25px 0px;
      top: 0;
    }

    .scheduler-table {
      display: block;

      .schedule-table {
        table-layout: auto;

        thead {
          position: relative;
          top: auto;
          width: 100%;
          table-layout: initial;
          display: block;

          // td {
          //   &:first-child {
          //     width: 400px;
          //   }
          // }
        }

        tbody {
          margin-top: 0;
        }

        tr td {
          width: 150px;
        }

        .schedule-head {
          width: 100%;
        }
      }
    }
  }
  /*Schedule*/
}

@media (min-width: 1200px) {

  .staffAvailInfo {

    .form-control {
      width: 40%;
    }
  }
}

@media screen and (max-width:1325px) {

  .report-container .row {

&::after {
  content: '';
  clear: both;
  display: table;
}
display: block;

.col-md-4 {

width: 50%;
float: left;
max-width: 100%;

.w200 {
    width:100%;
  }

button, .btn-group {
   width: 100%;
   display: block;
}

&:first-child {
  float:none;
  max-width: 100%;
  width: 100%;
  
}
}

}

}

@media screen and (max-width:767px) {

  .report-container .row {

&::after {
  content: '';
  clear: both;
  display: table;
}
display: block;

.col-md-4 {

width: 100%;
float: left;
max-width: 100%;
margin: bottom 15px !important;

.w200 {
    width:100%;
  }

button, .btn-group {
   width: 100%;
   display: block;
}

&:first-child {
  float:none;
  max-width: 100%;
  width: 100%;
  
}
}

}

}