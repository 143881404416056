/********* margin & padding  **********/
$spaceamounts: (-5, -4, 0, 3, 7, 10, 12, 15, 20, 30, 43, 50, 57, 43, 140, 160);
$sides: (top, bottom, left, right, all);

@each $space in $spaceamounts {

  @each $side in $sides {

    @if $side == 'all' {
      .m-#{$space} {
        margin: #{$space}px;
      }

      .p#{$space} {
        padding: #{$space}px;
      }
    } @else {
      .m#{str-slice($side, 0, 1)}-#{$space} {
        margin-#{$side}: #{$space}px;
      }

      .p#{str-slice($side, 0, 1)}#{$space} {
        padding-#{$side}: #{$space}px;
      }
    }
  }
}

/********* margin & padding  **********/

.h-100vh {
  height: 100vh;
}

.w-70 {
  width: 70%;
}

.widm-140 {
  max-width: 140px;
}

.wid-150 {
  width: 150px;
}

.w-100px {
  width: 100px;
}

.w-120px {
  width: 120px;
}

.w-14px {
  width: 14px;
}

.w-140px {
  width: 140px;
}

.w-22px {
  width: 22px;
}

.dashed-border {
  border-style: dashed;
}

.border-dashed {
  border-style: dashed;
}

.op1 {
  opacity: 1;
}

.op-25 {
  opacity: 0.25;
}

.op-32 {
  opacity: 0.32;
}

.op-05 {
  opacity: 0.5;
}

.op-09 {
  opacity: 0.9;
}

.op-1 {
  opacity: 1;
}

.rot-180 {
  transform: rotate(180deg);
}

.h33 {
  height: 33px;
}

.h20 {
  height: 20px;
}

.h22 {
  height: 22px;
}

.h15 {
  height: 15px;
}

.w-29 {
  width: 29%;
}

.w-14 {
  width: 14%;
}

.w-340 {
  width: 340px;
}

.h-214 {
  height: 214px;
}

.w200 {
  width: 200px;
}

.h-460 {
  height: 460px;
}

.c-pointer {
  cursor: pointer;
}

.c-dispointer {
  pointer-events: none;
}

.b-shawdow {
  box-shadow: 0 0 5px rgba(210, 210, 210, 0.8);
}

.mt-45 {
  margin-top: 45px;
}

.h-486 {
  height: 486px;
}

.pl-30 {
  padding-left: 30px;
}

.white-space {
  white-space: nowrap;
}

.mb-57px {
  margin-bottom: 57px;
}
