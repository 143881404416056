body {
  font-family: 'Roboto', sans-serif;
  background-color: $grey-b;

  @extend .fs15;
  height: 100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

a {

  &:focus {
    outline: none;
  }
}
/*=======Custom Scroll==========*/

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $grey-c;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background: $blue-b;
  border-radius: 10px;

  &:hover {
    background: $blue-b;
  }
}

/*=======Custom Scroll==========*/

.invalid-field {
  border-color: $red-h !important;
}

.dropdown-toggle {
  background-color: transparent;
  border-color: $grey-d;
  border-width: 2px;
  border-radius: 0;
  text-align: left;
  border: none;
  border-bottom: solid 1px #dfe2e6;

  @extend .color-dark-grey;
  outline: 0;
  box-shadow: none;

  &::after {
    border: none;
  }

  ul {

    li {
      padding: 5px 10px;
    }
  }
}

.heading-border {
  border-left: 3px solid $light-blue;
  margin-bottom: 20px;
}

.roaster-shadow {
  box-shadow: 0px 3px 6px #adadad29;
}

.schedule-table {
  table-layout: fixed;

  .schedule-head {
    position: absolute;
    background: inherit;
    z-index: 9;
  }

  thead {
    overflow: hidden;

    tr {

      td {
        display: flex;
        justify-content: center;

        @extend .fs12;
        border: none;

        span {
          display: contents;

          @extend .fs16;
        }
      }
    }
  }

  .tbody-title {
    color: $white;

    @extend .fs20;
    font-weight: normal;
    width: 100%;
  }

  .jr-plan {

    ul {

      li {
        display: flex;
      }
    }
  }

  tr {
    display: flex;

    td {
      width: 12.5%;
    }
  }
}

.card-green {
  background-color: $green-c;
  color: $green-d;
  width: 100%;
}

.card-yello {
  background-color: $yellow-c;
  color: $yellow-d;
  width: 100%;
}

.card-red {
  background-color: $red-d;
  color: $red-e;
  width: 100%;
}

.card-blue {
  background-color: $blue-d;
  color: $blue-e;
  width: 100%;
}

.card-grey {
  background-color: $grey-e;
  color: $white;
  width: 100%;
}

.dropdown-menu-right {
  right: 0 !important;
  left: auto !important;
}

.custom-dd-menu {

  &.dropdown-menu {
    border: none;
    box-shadow: 0px 0px 3px $grey-f;
    border-radius: 4px;
    margin: 0px;
    padding: 0px;

    li {
      padding: 0px;

      a {
        padding: 13px 30px;
        position: relative;

        img {
          position: absolute;
          left: 19px;
          top: 19px;
        }

        .dd-txt {

          @extend .pl20;
        }
      }
    }
  }
}

.custom-icon {
  background-color: $light-blues;
  border-radius: 50%;
  line-height: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
}

.calender-icon {
  color: $black;
}

.card-body .p-left-0 {
  padding-left: 0;
}

.display-inline {

  li {
    display: inline;
    padding-left: 30px;
  }
}

.square {
  height: 50px;
  width: 40px;
  background: $white;
}

.square-left {
  display: inline;
}

.square-li {

  li {
    list-style-type: square;
    padding-right: 35px;
  }
}

.display-inline-block {
  display: inline-block;
}

.scheduler-table {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;

  thead {
    position: fixed;
    top: 159px;
    width: calc(100% - 290px);
    background: $white;
    table-layout: fixed;
    display: table;
    margin-left: 0px;
    z-index: 999;
  }

  tbody {
    display: block;
    margin-top: 145px;
    z-index: 0;
  }
}

.roaster-date {
  top: 30px;
  background: $white;
  position: fixed;
  width: calc(100% - 290px);
  flex-wrap: wrap;
  padding: 30px 0px;
  margin-left: 0px;
  z-index: 999;
}

.cancelcreateform {
  background-color: $white;
  color: $black;
  opacity: 0.54;
}

.inputwithicon {

  input {
    padding-left: 96px;
  }

  .img1 {
    left: 18px;
    top: 5px;
    padding: 9px 8px;
    width: 38px;
  }

  .img2 {
    left: 57px;
    top: 16px;
  }
}

.custommargin {
  margin-top: 60px !important;
}

.camicon {
  color: $white;
  font-size: 100px;
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.imgprofile {
  max-width: 136px;
}

.overlay-dark {
  background-color: $blue-g;
  opacity: 0.39;
}

.card.imgprofile {
  border: 0 !important;
}

.filterdropdown.dropdown-menu {
  min-width: 7.5rem !important;
}

.timezonestyle {
  max-height: 412px;
  overflow: auto;
  list-style: none;
}

.imgprofile input[type='file'] {
  display: none;
}

.r101img {
  width: 49px;
}

.borderrad-50 {
  border-radius: 1.85rem;
}

.client-layout,
.staff-layout {

  .circle-icon {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    padding: 8px;

    &.map-icon {
      color: $green-a;
      background: $green-b;
      opacity: 0.5;
    }

    &.compress-icon {
      background: $grey-a;
      opacity: 1;
    }

    &.file-icon {
      color: $yellow-b;
    }

    &.ban-icon {
      color: $red-c;
    }

    &.medal-icon {
      background: $dark-blue;
      color: $yellow-b;
    }

    &.phone-volume-icon,
    &.mobile-alt-icon,
    &.envelope-icon {
      color: $dark-blue;
      background: $grey-i;
    }
  }

  .fa-plus {
    font-size: 10px;
  }

  .eye-icon {
    color: $green-a;
  }

  .delete-icon {
    color: $dark-cred;
  }

  .pen-icon {
    color: $blue-a;
  }

  .down-icon {
    color: $blue-a;
  }
}

.agm-map {
  height: 400px;
  width: 100%;
}

.incident-text-overflow,
.equipment-text-overflow {
  max-width: 100px;
  word-wrap: break-word;
}

.jumb-label {
  margin-left: 70px;
  margin-top: 8px;
}

.auth-header {
  background-color: $blue-b;

  .logo {
    max-height: 40px;
  }
}

.login-bdr {
  border-width: thin;
  border-style: solid;
}

.auth-card {
  border: 0;
  padding-left: 0;
  padding-right: 0;
}

.availability-card-layout {

  .custom-time-picker {

    .time-picker-container {
      position: absolute;
      left: 0;
      background-color: $white;
      z-index: 999;
      box-shadow: 0px 0px 14px #00000029;
      border-radius: 5px;
      padding: 15px;
      top: 50px;

      &::before {
        content: '';
        position: absolute;
        left: 30%;
        top: -7px;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 7px solid transparent;
        border-bottom: 7px solid $white;
      }

      .form-control {
        width: 100%;
      }
    }

    .form-control {
      background: rgba(28, 80, 218, 0.1);
      border-color: rgba(28, 80, 218, 0.1);
      border-radius: 37px;
      border: 0;
      font-size: 13px;
      height: 30px;

      &:focus {
        background: rgba(28, 80, 218, 0.1);
        border-color: rgba(28, 80, 218, 0.1);
        box-shadow: none;
      }
    }
  }
}

tr.hide-table-padding td {
  padding: 0;
}

.report-container {

  .report-container-item {

    .dropdown-item {
      padding: 8px;

      &:hover {
        background: transparent;
      }
    }
  }

  .dropdown-menu {
    min-width: 200px;
    padding: 10px;
    max-height: 215px;
    overflow: auto;

    label {
      font-weight: normal !important;
      white-space: initial;
      font-weight: normal !important;
      /* word-break: break-all; */
      word-wrap: break-word;
      display: inline-block;
      max-width: calc(100% - 16px);
    }
  }

  .report-tabs {

    .nav-tabs {
      border: none;

      .nav-item {
        color: $dark-grey;

        a {
          color: $dark-grey;
          outline: none;
          border: none;
        }

        .active {
          border: none;
          border-bottom: 3px solid #1331c8;
          background: transparent;
          color: $light-black;
        }
      }
    }
  }
}

.disabled-field {
  pointer-events: none;
  display: none;
}
