.roaster-sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  background-color: $dark-blue;
  width: 250px;
  color: $white;
  height: 100%;
  overflow-y: auto;
  padding: 0px 0;

  .list-group {
    padding: 20px;

    .list-group-item {
      background-color: transparent;
      border: none;
      cursor: pointer;
      outline: 0;

      &:hover,
      &.active {
        background-color: $blue-c;
        border-radius: 3px;
      }

      img {
        margin-right: 10px;
        width: 14px;
        margin-top: -5px;
      }
    }
  }
}

.own-sidemenu-main {

  &.sidemenuopend {

    .roaster-sidebar {
      transition: all 0.5s;
    }
  }

  &.sidemenuclosed {

    .roaster-sidebar {
      transition: all 0.5s;
      width: 0px;
    }
  }
}

.page-content.show_pagecontent {
  transition: all 0.5s;
  margin-left: 30px;
}

.page-content {
  transition: all 0.5s;
}

.toggle_icon {
  cursor: pointer;
}
