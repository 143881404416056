.header {

  .logo {
    max-width: 60px;
  }

  .navbar-brand {
    width: 250px;
    text-align: center;
    background-color: $dark-blue;
    color: $white;
    padding: 15px;
  }

  .notify-badge {
    position: absolute;
    top: -9px;
    right: -6px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-color: $red-f;
    color: $white;
    border-radius: 50%;
    text-align: center;
  }

  .user-profile {

    img {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}
